import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';


const FGI = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('PN',phoneNumber)
    // Perform the action on submit
    axios.post('https://au18kgw95g.execute-api.us-west-2.amazonaws.com/Prod', { PhoneNumber: `+1${phoneNumber}` })
      .then(response => {
        console.log(response);
        setSubmitted(true)
        // do something with the response
      })
      .catch(error => {
        console.log(error);
        // handle the error
      });
    setSubmitted(true)
  }

  return (
    <div >
      <br />
      <h3 className='lato'>Be fearful when others are greedy and greedy when others are fearful.</h3>
      <h3 className='lato'>- Warren Buffet</h3>
      <br />
      <h2 className='lato' >Subscribe for a daily text below:</h2>
      <form onSubmit={handleSubmit}>
        <input
          className='lato text-center'
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          style={{ textAlign: 'center' }}
        />
        <br />
        <br />
        {!submitted ? (
          <button type="submit" className='submit'>Submit</button>
        ) : (
          <body>Submitted!</body>
        )}
      </form>
      <br />
      <br />
      <br />
      <img src={require('./screenshot.png')}
        style={{ width: 200, height: "auto" }}
        alt='image2' />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <FGI />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
